
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function PropertyStructuredData({ property, pageurl, metadesc }) {
  var ldJson = {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "Place",
        "name": `${property.building ? property.building+' ' : ''} for ${property.search_type} in ${property.area}`,
        "description": `${metadesc}`,
        "image": `${property.images[0]?.url}`,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": `${property.building_name}, ${property?.address?.address1}`,
          "addressLocality": `${property?.area}`,
          "addressCountry": `${property?.address?.address3}`
        }

      },

      {
        "@type": "Offer",
        "price": `${property.price}`,
        "priceCurrency": "dhiram",
        "url": `${pageurl}`

      }
    ] 
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default PropertyStructuredData
