import React, { useState, Fragment } from "react";
import { Link } from "gatsby";
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import { useLocation } from "@reach/router"
import ArabianHolidaysLogo from "../images/arabian-holidays-logo-color.svg"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { toTitleCase } from "../components/property-search/utils"
// import BrandLogo from "../images/logo-black.svg"
import BrandLogo from "../images/arabian-estates-logo-black.svg"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import DetailsHead from "../components/SellPlanning/SellPlanning"
import PropertyContent from "../components/PropertyContent/PropertyContent"
import { numberFormat } from "../common/site/utils";
import FixedButtons from "../components/FixedButtons/FixedButtons";
import PropertyStructuredData from "../components/Seo/PropertyStructuredData";


const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

const PROPERTY_DETAILS = gql`
  query GetProperty($id: String!) {
    properties(where: { id: $id, publish:true}) {
      id
      crm_id
      status
      search_type
      virtual_tour
      bedroom
      department
      bathroom
      building
      address
      title
      accomadation_summary
      price
      area
      price_qualifier
      display_address
      description
      long_description
      crm_negotiator_id
      latitude
      longitude
      floorplan
      images
      imagetransforms
      accommodationMasterCode
      availabilities
      extra
      userCode
      loginGA
      occupationalRules
      peopleCapacity
    }
  }
`


const PropertyDetailsPage = (props) => {
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  //
  var { pathname } = useLocation();
  const isHoliday = pathname?.includes("holiday-lets");
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  let myid_filt = pageurl.split("?")
  let mypageurl = myid_filt[0]
  let property_slug = mypageurl.split("-")
  let id = property_slug[property_slug.length - 1]
  id = id.replace("/", "")

  const {
    loading,
    error,
    data: property_details,
  } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id },
  })

  var metatitle = ""
  var metadesc = ""
  var seo_image_url = ""

  const property = property_details?.properties[0] || {};
  const wrapClass = property.department === 'short-lettings' ? 'holiday-lets' : '';
  
  if (loading)
    return (
      <section className="loader-wrapper">
        <div id="loader-wrapper">
          <div id="loader" className="new-loader">
            <img
              src={isHoliday ? ArabianHolidaysLogo : BrandLogo}
              className="loader-logo"
              alt="logo"
            />
          </div>
        </div>
      </section>
    )

  return (
    <div className={`property_details_page ${wrapClass}`}>
      <Layout isHoliday={property.department === 'short-lettings' && true}>
        {property_details &&
          property_details.properties &&
          property_details.properties.length > 0
          ? property_details.properties.map((data, i) => {
            var search_action = "sale"
            if (data.search_type == "lettings") {
              search_action = "rent";
            }
            let building = Array.isArray(data.building) ? data.building[0] || "property" : data.building || "property";
            metatitle = data?.department === "short-lettings" ? "Short term rental " + toTitleCase(building) + " for " + search_action + " with " + data.bedroom + " bedrooms in " + data.display_address + " at AED " + numberFormat(data.price) + "" : "" + toTitleCase(building) + " for " + search_action + " with " + data.bedroom + " bedrooms in " + data.display_address + " at AED " + numberFormat(data.price) + ""
            metadesc = "Know the details of " + building.toLowerCase() + " for " + search_action + " with " + data.bedroom + " bedrooms in " + data.display_address + " at AED " + numberFormat(data.price) + ". Book a viewing to get assistance in finding the right " + building.toLowerCase() + " for you."

            let processedImages = JSON.stringify({});
            seo_image_url = data.images && data.images.length > 0 && data.images[0].url;
            if (data?.imagetransforms?.images_Transforms) {
              processedImages = data?.imagetransforms.images_Transforms;
              var resized_images = JSON.parse(processedImages);
              const SrcCftle = data.images && data.images.length > 0 && data.images[0].url?.substring(data.images && data.images.length > 0 && data.images[0].url?.indexOf(STAGE_ENV));
              const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['440x288'] : data.images && data.images.length > 0 && data.images[0].url;
              //setResizedImage(ProcessedUrl);              
              seo_image_url = ProcessedUrl
            }
            // if (typeof document !== `undefined`) {
            //     setTimeout(function () {
            //         var pagehead = document.getElementsByTagName('head')[0];
            //         var social_share_meta = '<title>' + metatitle + ' | Arabian Estates Properties</title> <meta name="description" content="' + metadesc + '"> <meta property="og:title" content="' + metatitle + '"> <meta property="og:description" content="' + metadesc + '"> <meta property="og:image" content="' + seo_image_url + '"> <meta name="twitter:title" content="' + metatitle + '"> <meta name="twitter:description" content="' + metadesc + '"> <meta name="twitter:image" content="' + seo_image_url + '">';
            //         pagehead.innerHTML = social_share_meta + pagehead.innerHTML;
            //     }, 450);
            // }
            return (
              <>
                <PropertyStructuredData
                  property={data}
                  metadesc={metadesc}
                  pageurl={pageurl}
                />
                <Seo title={metatitle + " | Arabian Estates"} description={metadesc} image={seo_image_url ? seo_image_url : data.images.length > 0 ? data.images[0].url : null} />
                <h1 className="d-none">{metatitle}</h1>
                <DetailsHead type={data.department === "short-lettings" ? data.department : data.search_type} status={data.status} />
                <PropertyBanner propertyData={data} />
                <PropertyContent {...data} pageurl={pageurl} property_title={data.display_address} property_img={data?.images[0]?.url} />
                <FixedButtons pageurl={pageurl} property_title={data.display_address} property_img={data?.images[0]?.url} />
              </>
            )
          }) :
          <>
            <Seo title="404: Not found" />
            <div className="error-page">
              <h1>We're sorry, the page you were looking for cannot be found.</h1>
              <p>This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
              <Link to="/contact-arabian-estates/" className="btn">Contact Us</Link>
            </div>
          </>}
      </Layout>
    </div>
  )
}

export default PropertyDetailsPage